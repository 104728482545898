import React, { useEffect, useState } from "react";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import {
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import "./index.scoped.css";
import CasinoPage from "./components/CasinoPage";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";
export default function DragonTigerOneDay() {
  const GAME_ID = "DRAGON_TIGER_6";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);

  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.dragonTiger1Day}
      GAME_ID={GAME_ID}
      shortName={"dt1day"}
      gameName={"Dragon Tiger one day"}
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="casino-table ng-tns-c1798067373-5 ng-star-inserted"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-table-box ng-tns-c1798067373-5"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-left-box ng-tns-c1798067373-5"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-header ng-tns-c1798067373-5"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-5"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="d-lg-none d-block ng-tns-c1798067373-5"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min-max-dt20 ng-tns-c1798067373-5"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-5"
                      >
                        Min:{" "}
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-5"
                      >
                        100
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-5"
                      >
                        Max:{" "}
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-5"
                      >
                        300000
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="d-lg-block d-none ng-tns-c1798067373-5"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-5"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapseranged1014"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseranged1014"
                      className="minmax-btn collapsed ng-tns-c1798067373-5"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-5"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapseranged1014"
                      className="range-collpase collapse ng-tns-c1798067373-5"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-5"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-5"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-5"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-5"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-5"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-5"
                        >
                          300000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-5"
              >
                Back
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box lay ng-tns-c1798067373-5"
              >
                Lay
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-body ng-tns-c1798067373-5"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-5 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-5"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-5"
                  >
                    {" "}
                    Dragon{" "}
                  </div>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                  >
                    {getCasinoPlExposureByRunner(casinoPlData, "Dragon")}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-5 casino-odds-box-row ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className={`casino-odds-box back ng-tns-c1798067373-5 `}
                    onClick={() => {
                      setBet("DRAGON", casinoData, dispatch, "back");
                    }}
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-5"
                    >
                      {getOddForEntity("DRAGON", casinoData)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                    >
                      {getOddForEntity("DRAGON", casinoData, true, true)}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      setBet("DRAGON", casinoData, dispatch, "lay");
                    }}
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box lay ng-tns-c1798067373-5"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-5"
                    >
                      {getOddForEntity("DRAGON", casinoData, false)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                    >
                      {getOddForEntity("DRAGON", casinoData, false, true)}
                    </p>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-5 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-5"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-5"
                  >
                    {" "}
                    Tiger{" "}
                  </div>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                  >
                    {getCasinoPlExposureByRunner(casinoPlData, "Tiger")}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-5 casino-odds-box-row ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <div
                    onClick={() => {
                      setBet("TIGER", casinoData, dispatch, "back");
                    }}
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-5"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-5"
                    >
                      {getOddForEntity("TIGER", casinoData)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                    >
                      {getOddForEntity("TIGER", casinoData, true, true)}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      setBet("TIGER", casinoData, dispatch, "lay");
                    }}
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box lay ng-tns-c1798067373-5"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-5"
                    >
                      {getOddForEntity("TIGER", casinoData, false)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                    >
                      {getOddForEntity("TIGER", casinoData, false, true)}
                    </p>
                  </div>
                </div>
              </div>
              {/**/}
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-right-box dtpair ng-tns-c1798067373-5 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds text-center ng-tns-c1798067373-5"
            >
              {getOddForEntity("Pair", casinoData)}
            </div>
            <div
              onClick={() => {
                setBet("Pair", casinoData, dispatch, "back");
              }}
              _ngcontent-ng-c1798067373=""
              className={`ng-tns-c1798067373-5 back casino-odds-box casino-odds-box-theme ${
                casinoData?.data?.response?.autoTime < 5
                  ? "suspended-box"
                  : ""
              }`}
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="casino-odds ng-tns-c1798067373-5"
              >
                Pair
              </span>
            </div>
            <p _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-5">
              {getCasinoPlExposureByRunner(casinoPlData, "Pair")}
            </p>
            <div
              _ngcontent-ng-c1798067373=""
              className="d-lg-none d-block w-100 text-end ng-tns-c1798067373-5"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="min-max-dt20 ng-tns-c1798067373-5"
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-5"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                  >
                    Min:{" "}
                  </b>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                  >
                    100
                  </span>
                </span>
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-5"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                  >
                    Max:{" "}
                  </b>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                  >
                    300000
                  </span>
                </span>
              </div>
            </div>
          </div>
          {/**/}
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-table-box mt-3 ng-tns-c1798067373-5"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-left-box ng-tns-c1798067373-5"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-header ng-tns-c1798067373-5"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-5"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="d-lg-none d-block ng-tns-c1798067373-5"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min-max-dt20 ng-tns-c1798067373-5"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-5"
                      >
                        Min:{" "}
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-5"
                      >
                        100
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-5"
                      >
                        Max:{" "}
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-5"
                      >
                        300000
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="d-lg-block d-none ng-tns-c1798067373-5"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-5"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapseranged112"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseranged112"
                      className="minmax-btn collapsed ng-tns-c1798067373-5"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-5"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapseranged112"
                      className="range-collpase collapse ng-tns-c1798067373-5"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-5"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-5"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-5"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-5"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-5"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-5"
                        >
                          300000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-5"
              >
                Even
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-5"
              >
                Odd
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-body ng-tns-c1798067373-5"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-5"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-5"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-5"
                  >
                    Dragon
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-5 back casino-odds-box ng-star-inserted ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => {
                    setBet("Dragon Even", casinoData, dispatch, "back");
                  }}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-5"
                  >
                    {getOddForEntity("Dragon Even", casinoData)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                  >
                    {getCasinoPlExposureByRunner(casinoPlData, "Dragon Even")}
                  </p>
                </div>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-5 back casino-odds-box ng-star-inserted ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => {
                    setBet("Dragon Odd", casinoData, dispatch, "back");
                  }}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-5"
                  >
                    {getOddForEntity("Dragon Odd", casinoData)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                  >
                    {getCasinoPlExposureByRunner(casinoPlData, "Dragon Odd")}
                  </p>
                </div>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-5"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-5"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-5"
                  >
                    Tiger
                  </div>
                </div>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-5 back casino-odds-box ng-star-inserted ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => {
                    setBet("Tiger Even", casinoData, dispatch, "back");
                  }}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-5"
                  >
                    {getOddForEntity("Tiger Even", casinoData)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                  >
                    {getCasinoPlExposureByRunner(casinoPlData, "Tiger Even")}
                  </p>
                </div>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-5 back casino-odds-box ng-star-inserted ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => {
                    setBet("Tiger Odd", casinoData, dispatch, "back");
                  }}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-5"
                  >
                    {getOddForEntity("Tiger Odd", casinoData)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                  >
                    {getCasinoPlExposureByRunner(casinoPlData, "Tiger Odd")}
                  </p>
                </div>
                {/**/}
                {/**/}
                {/**/}
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-right-box dtredblack ng-tns-c1798067373-5"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-header ng-tns-c1798067373-5"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-5"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="d-lg-none d-block ng-tns-c1798067373-5"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min-max-dt20 ng-tns-c1798067373-5"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-5"
                      >
                        Min:{" "}
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-5"
                      >
                        100
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-5"
                      >
                        Max:{" "}
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-5"
                      >
                        300000
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="d-lg-block d-none ng-tns-c1798067373-5"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-5"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapseranged12"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseranged12"
                      className="minmax-btn collapsed ng-tns-c1798067373-5"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-5"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapseranged12"
                      className="range-collpase collapse ng-tns-c1798067373-5"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-5"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-5"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-5"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-5"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-5"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-5"
                        >
                          300000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-5"
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-5"
                >
                  Red
                </span>
                <span
                  _ngcontent-ng-c1798067373=""
                  className="card-icon ms-1 ng-tns-c1798067373-5"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="card-red ng-tns-c1798067373-5"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                      src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/heart.png"
                    />
                  </span>
                </span>
                <span
                  _ngcontent-ng-c1798067373=""
                  className="card-icon ms-1 ng-tns-c1798067373-5"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="card-red ng-tns-c1798067373-5"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                      src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/diamond.png"
                    />
                  </span>
                </span>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-5"
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-5"
                >
                  Black
                </span>
                <span
                  _ngcontent-ng-c1798067373=""
                  className="card-icon ms-1 ng-tns-c1798067373-5"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="card-black ng-tns-c1798067373-5"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                      src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/spade.png"
                    />
                  </span>
                </span>
                <span
                  _ngcontent-ng-c1798067373=""
                  className="card-icon ms-1 ng-tns-c1798067373-5"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="card-black ng-tns-c1798067373-5"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                      src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/club.png"
                    />
                  </span>
                </span>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-body ng-tns-c1798067373-5"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-5"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-5"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-5"
                  >
                    Dragon
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-5 back casino-odds-box ng-star-inserted ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => {
                    setBet("Dragon Red", casinoData, dispatch, "back");
                  }}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-5"
                  >
                    {getOddForEntity("Dragon Red", casinoData)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                  >
                    {getCasinoPlExposureByRunner(casinoPlData, "Dragon Red")}
                  </p>
                </div>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-5 back casino-odds-box ng-star-inserted ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => {
                    setBet("Dragon Black", casinoData, dispatch, "back");
                  }}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-5"
                  >
                    {getOddForEntity("Dragon Black", casinoData)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                  >
                    {getCasinoPlExposureByRunner(
                      casinoPlData,
                      "Dragon Black"
                    )}
                  </p>
                </div>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-5"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-5"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-5"
                  >
                    Tiger
                  </div>
                </div>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-5 back casino-odds-box ng-star-inserted ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => {
                    setBet("Tiger Red", casinoData, dispatch, "back");
                  }}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-5"
                  >
                    {getOddForEntity("Tiger Red", casinoData)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                  >
                    {getCasinoPlExposureByRunner(casinoPlData, "Tiger Red")}
                  </p>
                </div>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <div
                  onClick={() => {
                    setBet("Tiger Black", casinoData, dispatch, "back");
                  }}
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-5 back casino-odds-box ng-star-inserted ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-5"
                  >
                    {getOddForEntity("Tiger Black", casinoData)}
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                  >
                    {getCasinoPlExposureByRunner(casinoPlData, "Tiger Black")}
                  </p>
                </div>
                {/**/}
                {/**/}
                {/**/}
              </div>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-table-full-box dt1day-other-odds mt-3 ng-tns-c1798067373-5"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-header ng-tns-c1798067373-5"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-nation-detail ng-tns-c1798067373-5"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="min_max_collapse ng-tns-c1798067373-5"
              >
                <a
                  _ngcontent-ng-c1798067373=""
                  data-bs-toggle="collapse"
                  href="#collapseranged22"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseranged22"
                  className="minmax-btn collapsed ng-tns-c1798067373-5"
                >
                  <i
                    _ngcontent-ng-c1798067373=""
                    className="fas fa-info-circle ng-tns-c1798067373-5"
                  />
                </a>
                <div
                  _ngcontent-ng-c1798067373=""
                  id="collapseranged22"
                  className="range-collpase collapse ng-tns-c1798067373-5"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="min_max_span ms-0 ng-tns-c1798067373-5"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                    >
                      Min:
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                    >
                      100
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="min_max_span ms-1 ng-tns-c1798067373-5"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                    >
                      Max:
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-5"
                    >
                      300000
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box ng-tns-c1798067373-5"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="card-icon ms-1 ng-tns-c1798067373-5"
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="card-black ng-tns-c1798067373-5"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                    src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/club.png"
                  />
                </span>
              </span>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box ng-tns-c1798067373-5"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="card-icon ms-1 ng-tns-c1798067373-5"
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="card-red ng-tns-c1798067373-5"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                    src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/diamond.png"
                  />
                </span>
              </span>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box ng-tns-c1798067373-5"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="card-icon ms-1 ng-tns-c1798067373-5"
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="card-black ng-tns-c1798067373-5"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                    src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/heart.png"
                  />
                </span>
              </span>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box ng-tns-c1798067373-5"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="card-icon ms-1 ng-tns-c1798067373-5"
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="card-red ng-tns-c1798067373-5"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-5"
                    src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/spade.png"
                  />
                </span>
              </span>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-body ng-tns-c1798067373-5"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-5"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-5"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-5"
                >
                  Dragon
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-5 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => {
                  setBet("Dragon Spade", casinoData, dispatch, "back");
                }}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-5"
                >
                  {getOddForEntity("Dragon Spade", casinoData)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-5"
                >
                  {getCasinoPlExposureByRunner(casinoPlData, "Dragon Spade")}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-5 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => {
                  setBet("Dragon Heart", casinoData, dispatch, "back");
                }}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-5"
                >
                  {getOddForEntity("Dragon Heart", casinoData)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-5"
                >
                  {getCasinoPlExposureByRunner(casinoPlData, "Dragon Heart")}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-5 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => {
                  setBet("Dragon Club", casinoData, dispatch, "back");
                }}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-5"
                >
                  {getOddForEntity("Dragon Club", casinoData)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-5"
                >
                  {getCasinoPlExposureByRunner(casinoPlData, "Dragon Club")}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-5 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => {
                  setBet("Dragon Diamond", casinoData, dispatch, "back");
                }}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-5"
                >
                  {getOddForEntity("Dragon Diamond", casinoData)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-5"
                >
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "Dragon Diamond"
                  )}
                </p>
              </div>
              {/**/}
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-5"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-5"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-5"
                >
                  Tiger
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-5 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => {
                  setBet("Tiger Spade", casinoData, dispatch, "back");
                }}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-5"
                >
                  {getOddForEntity("Tiger Spade", casinoData)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-5"
                >
                  {getCasinoPlExposureByRunner(casinoPlData, "Tiger Spade")}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-5 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => {
                  setBet("Tiger Heart", casinoData, dispatch, "back");
                }}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-5"
                >
                  {getOddForEntity("Tiger Heart", casinoData)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-5"
                >
                  {getCasinoPlExposureByRunner(casinoPlData, "Tiger Heart")}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-5 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => {
                  setBet("Tiger Diamond", casinoData, dispatch, "back");
                }}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-5"
                >
                  {getOddForEntity("Tiger Diamond", casinoData)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-5"
                >
                  {getCasinoPlExposureByRunner(casinoPlData, "Tiger Diamond")}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-5 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => {
                  setBet("Tiger Club", casinoData, dispatch, "back");
                }}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-5"
                >
                  {getOddForEntity("Tiger Club", casinoData)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-5"
                >
                  {getCasinoPlExposureByRunner(casinoPlData, "Tiger Club")}
                </p>
              </div>
              {/**/}
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
