import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import {
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import CasinoPage from "./components/CasinoPage";
import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
export default function BTable() {
  const GAME_ID = "BOLLYWOOD_TABLE";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  const abc = ["A", "B", "C", "D", "E", "F"];
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);

  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.bollyWoodTable}
      GAME_ID={GAME_ID}
      shortName={"bollywood"}
      gameName={"Bollywood Table"}
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="casino-table ng-tns-c1798067373-0 ng-star-inserted"
        data-v-53a3d43f=""
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="desktop-design d-lg-block d-none w-100 ng-tns-c1798067373-0"
          data-v-53a3d43f=""
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-box casino-table-box_top_bg ng-tns-c1798067373-0"
            data-v-53a3d43f=""
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="w-100 text-end ng-tns-c1798067373-0"
              data-v-53a3d43f=""
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="min_max_collapse ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                <a
                  _ngcontent-ng-c1798067373=""
                  data-bs-toggle="collapse"
                  href="#collapserangebollywood12"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapserangebollywood12"
                  className="minmax-btn collapsed ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <i
                    _ngcontent-ng-c1798067373=""
                    className="fas fa-info-circle ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  />
                </a>
                <div
                  _ngcontent-ng-c1798067373=""
                  id="collapserangebollywood12"
                  className="range-collpase collapse ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="min_max_span ms-0 ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      Min:
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      {" "}
                      100{" "}
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="min_max_span ms-1 ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      Max:
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0 ng-star-inserted"
                        data-v-53a3d43f=""
                      >
                        {" "}
                        150000{" "}
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted"
              data-v-53a3d43f=""
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-name ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                  data-v-53a3d43f=""
                >
                  A.
                </span>{" "}
                DON{" "}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                />
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={` ng-tns-c1798067373-0 casino-odds-box-row ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                data-v-53a3d43f=""
              >
                <div
                  onClick={() => {
                    setBet("Don", casinoData, dispatch, "back");
                  }}
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    {getOddForEntity("don", casinoData, true)}
                  </span>
                </div>
                <div
                  onClick={() => {
                    setBet("Don", casinoData, dispatch, "lay");
                  }}
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box lay ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    {getOddForEntity("don", casinoData, false)}
                  </span>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                {getCasinoPlExposureByRunner(casinoPlData, "don")}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted"
              data-v-53a3d43f=""
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-name ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                  data-v-53a3d43f=""
                >
                  B.
                </span>{" "}
                Amar Akbar Anthony{" "}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                />
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={` ng-tns-c1798067373-0 casino-odds-box-row ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                data-v-53a3d43f=""
              >
                <div
                  onClick={() => {
                    setBet(
                      "amar Akbar Anthony",
                      casinoData,
                      dispatch,
                      "back"
                    );
                  }}
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    {getOddForEntity("amar Akbar Anthony", casinoData, true)}
                  </span>
                </div>
                <div
                  onClick={() => {
                    setBet("amar Akbar Anthony", casinoData, dispatch, "lay");
                  }}
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box lay ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    {getOddForEntity("amar Akbar Anthony", casinoData, false)}
                  </span>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                {getCasinoPlExposureByRunner(
                  casinoPlData,
                  "amar Akbar Anthony"
                )}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted"
              data-v-53a3d43f=""
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-name ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                  data-v-53a3d43f=""
                >
                  C.
                </span>{" "}
                Sahib Bibi Aur Ghulam{" "}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                />
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={` ng-tns-c1798067373-0 casino-odds-box-row ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                data-v-53a3d43f=""
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                  onClick={() => {
                    setBet(
                      "Sahib Bibi Aur Ghulam",
                      casinoData,
                      dispatch,
                      "back"
                    );
                  }}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    {getOddForEntity(
                      "Sahib Bibi Aur Ghulam",
                      casinoData,
                      true
                    )}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box lay ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                  onClick={() => {
                    setBet(
                      "Sahib Bibi Aur Ghulam",
                      casinoData,
                      dispatch,
                      "lay"
                    );
                  }}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    {getOddForEntity(
                      "Sahib Bibi Aur Ghulam",
                      casinoData,
                      false
                    )}
                  </span>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                {getCasinoPlExposureByRunner(
                  casinoPlData,
                  "Sahib Bibi Aur Ghulam"
                )}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted"
              data-v-53a3d43f=""
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-name ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                  data-v-53a3d43f=""
                >
                  D.
                </span>{" "}
                Dharam Veer{" "}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                />
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={` ng-tns-c1798067373-0 casino-odds-box-row ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                data-v-53a3d43f=""
              >
                <div
                  onClick={() => {
                    setBet("Dharam Veer", casinoData, dispatch, "back");
                  }}
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    {getOddForEntity("Dharam Veer", casinoData, true)}
                  </span>
                </div>
                <div
                  onClick={() => {
                    setBet("Dharam Veer", casinoData, dispatch, "lay");
                  }}
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box lay ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    {getOddForEntity("Dharam Veer", casinoData, false)}
                  </span>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                {getCasinoPlExposureByRunner(casinoPlData, "Dharam Veer")}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted"
              data-v-53a3d43f=""
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-name ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                  data-v-53a3d43f=""
                >
                  E.
                </span>{" "}
                Kis KisKo Pyaar Karoon{" "}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                />
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={` ng-tns-c1798067373-0 casino-odds-box-row ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                data-v-53a3d43f=""
              >
                <div
                  onClick={() => {
                    setBet(
                      "Kis Kis Ko Pyaar Karoon",
                      casinoData,
                      dispatch,
                      "back"
                    );
                  }}
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    {getOddForEntity(
                      "Kis Kis Ko Pyaar Karoon",
                      casinoData,
                      true
                    )}
                  </span>
                </div>
                <div
                  onClick={() => {
                    setBet(
                      "Kis Kis Ko Pyaar Karoon",
                      casinoData,
                      dispatch,
                      "lay"
                    );
                  }}
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box lay ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    {getOddForEntity(
                      "Kis Kis Ko Pyaar Karoon",
                      casinoData,
                      false
                    )}
                  </span>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                {getCasinoPlExposureByRunner(
                  casinoPlData,
                  "Kis Kis Ko Pyaar Karoon"
                )}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted"
              data-v-53a3d43f=""
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-name ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                  data-v-53a3d43f=""
                >
                  F.
                </span>{" "}
                Ghulam{" "}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                />
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={` ng-tns-c1798067373-0 casino-odds-box-row ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                data-v-53a3d43f=""
              >
                <div
                  onClick={() => {
                    setBet("Ghulam", casinoData, dispatch, "back");
                  }}
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    {getOddForEntity("Ghulam", casinoData, true)}
                  </span>
                </div>
                <div
                  onClick={() => {
                    setBet("Ghulam", casinoData, dispatch, "lay");
                  }}
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box lay ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    {getOddForEntity("Ghulam", casinoData, false)}
                  </span>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                {getCasinoPlExposureByRunner(casinoPlData, "Ghulam")}
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-box mt-3 ng-tns-c1798067373-0"
            data-v-53a3d43f=""
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-left-box left-odd-box ng-tns-c1798067373-0"
              data-v-53a3d43f=""
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="w-100 text-end ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="min_max_collapse ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <a
                    _ngcontent-ng-c1798067373=""
                    data-bs-toggle="collapse"
                    href="#collapserange2btodds"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapserange2btodds"
                    className="minmax-btn collapsed ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <i
                      _ngcontent-ng-c1798067373=""
                      className="fas fa-info-circle ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    />
                  </a>
                  <div
                    _ngcontent-ng-c1798067373=""
                    id="collapserange2btodds"
                    className="range-collpase collapse ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="min_max_span ms-0 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        Min:
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        {" "}
                        100{" "}
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="min_max_span ms-1 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        Max:
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0 ng-star-inserted"
                          data-v-53a3d43f=""
                        >
                          {" "}
                          50000{" "}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted"
                data-v-53a3d43f=""
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {" "}
                  Odd{" "}
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-book text-danger d-md-none ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  />
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={` ng-tns-c1798067373-0 casino-odds-box-row ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  data-v-53a3d43f=""
                >
                  <div
                    onClick={() => {
                      setBet("odd", casinoData, dispatch, "back");
                    }}
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      {getOddForEntity("odd", casinoData, true)}
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      setBet("odd", casinoData, dispatch, "lay");
                    }}
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box lay ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      {getOddForEntity("odd", casinoData, false)}
                    </span>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getCasinoPlExposureByRunner(casinoPlData, "odd")}
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-right-box right-odd-box ng-tns-c1798067373-0"
              data-v-53a3d43f=""
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                data-v-53a3d43f=""
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-end ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      className="minmax-btn collapsed ng-tns-c1798067373-0"
                      href="#collapserange2bt14"
                      aria-controls="collapserange2bt14"
                      data-v-53a3d43f=""
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="range-collpase collapse ng-tns-c1798067373-0"
                      id="collapserange2bt14"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          {" "}
                          100{" "}
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            data-v-53a3d43f=""
                          >
                            {" "}
                            50000{" "}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getOddForEntity("Dulha dulhan K-Q", casinoData, true)}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  data-v-53a3d43f=""
                  onClick={() => {
                    setBet("Dulha dulhan K-Q", casinoData, dispatch, "back");
                  }}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    Dulha Dulhan K-Q
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "Dulha dulhan K-Q"
                  )}
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                data-v-53a3d43f=""
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-end ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      className="minmax-btn collapsed ng-tns-c1798067373-0"
                      href="#collapserange2bt15"
                      aria-controls="collapserange2bt15"
                      data-v-53a3d43f=""
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="range-collpase collapse ng-tns-c1798067373-0"
                      id="collapserange2bt15"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          {" "}
                          100{" "}
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            data-v-53a3d43f=""
                          >
                            {" "}
                            50000{" "}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getOddForEntity("Barati J-A", casinoData, true)}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  data-v-53a3d43f=""
                  onClick={() => {
                    setBet("Barati J-A", casinoData, dispatch, "back");
                  }}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    Barati J-A
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getCasinoPlExposureByRunner(casinoPlData, "Barati J-A")}
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-box mt-3 ng-tns-c1798067373-0"
            data-v-53a3d43f=""
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-left-box ng-tns-c1798067373-0"
              data-v-53a3d43f=""
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="w-100 text-end ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="min_max_collapse ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <a
                    _ngcontent-ng-c1798067373=""
                    data-bs-toggle="collapse"
                    href="#collapserange2asdasd"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapserange2asdasd"
                    className="minmax-btn collapsed ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <i
                      _ngcontent-ng-c1798067373=""
                      className="fas fa-info-circle ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    />
                  </a>
                  <div
                    _ngcontent-ng-c1798067373=""
                    id="collapserange2asdasd"
                    className="range-collpase collapse ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="min_max_span ms-0 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        Min:
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        {" "}
                        100{" "}
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="min_max_span ms-1 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        Max:
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0 ng-star-inserted"
                          data-v-53a3d43f=""
                        >
                          {" "}
                          12500{" "}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                data-v-53a3d43f=""
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getOddForEntity("Red", casinoData, true)}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  data-v-53a3d43f=""
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0 ng-star-inserted"
                    data-v-53a3d43f=""
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                      onClick={() => {
                        setBet("Red", casinoData, dispatch, "back");
                      }}
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/heart.png"
                          data-v-53a3d43f=""
                        />
                      </span>
                    </span>
                    <span
                      onClick={() => {
                        setBet("Black", casinoData, dispatch, "back");
                      }}
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/diamond.png"
                          data-v-53a3d43f=""
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getCasinoPlExposureByRunner(casinoPlData, "Red")}
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                data-v-53a3d43f=""
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getOddForEntity("Black", casinoData, true)}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  data-v-53a3d43f=""
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0 ng-star-inserted"
                    data-v-53a3d43f=""
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-black ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/spade.png"
                          data-v-53a3d43f=""
                        />
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-black ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/club.png"
                          data-v-53a3d43f=""
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getCasinoPlExposureByRunner(casinoPlData, "Black")}
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-right-box right-cards ng-tns-c1798067373-0"
              data-v-53a3d43f=""
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="w-100 text-end ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="min_max_collapse ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <a
                    _ngcontent-ng-c1798067373=""
                    data-bs-toggle="collapse"
                    href="#collapserange24cardbt"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapserange24cardbt"
                    className="minmax-btn collapsed ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <i
                      _ngcontent-ng-c1798067373=""
                      className="fas fa-info-circle ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    />
                  </a>
                  <div
                    _ngcontent-ng-c1798067373=""
                    id="collapserange24cardbt"
                    className="range-collpase collapse ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="min_max_span ms-0 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        Min:
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        {" "}
                        100{" "}
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="min_max_span ms-1 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        Max:
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0 ng-star-inserted"
                          data-v-53a3d43f=""
                        >
                          {" "}
                          12500{" "}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <h4
                _ngcontent-ng-c1798067373=""
                className="w-100 text-center ng-tns-c1798067373-0"
                data-v-53a3d43f=""
                style={{ fontSize: 16 }}
              >
                <b
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  Cards 3.75
                </b>
              </h4>
              {["J", "Q", "K", "A"].map((card, index) => (
                <div
                  _ngcontent-ng-c1798067373=""
                  className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                  data-v-53a3d43f=""
                >
                  <div
                    onClick={() => {
                      setBet("Card " + card, casinoData, dispatch, "back");
                    }}
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-0 ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                    data-v-53a3d43f=""
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0 ng-star-inserted"
                      src={`https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/${card}.png`}
                      data-v-53a3d43f=""
                    />
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-book ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Card " + card
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="mobile-design d-lg-none d-block w-100 ng-tns-c1798067373-0"
          data-v-53a3d43f=""
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-box mobile-akbar ng-tns-c1798067373-0"
            data-v-53a3d43f=""
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="w-100 ng-tns-c1798067373-0"
              data-v-53a3d43f=""
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-header ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="min_max_span ms-0 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        Min:
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        {" "}
                        100{" "}
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="min_max_span ms-1 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        Max:
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0 ng-star-inserted"
                          data-v-53a3d43f=""
                        >
                          {" "}
                          150000{" "}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-body ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                {[
                  "DON",
                  "Amar Akbar Anthony",
                  "Sahib bibi aur ghulam",
                  "Dharam veer",
                  "Kis Kis ko pyaar karoon",
                  "Ghulam",
                ].map((item, index) => (
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
                    data-v-53a3d43f=""
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name d-flex w-100 ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                          data-v-53a3d43f=""
                        >
                          {abc[index]}.
                        </span>{" "}
                        {item}{" "}
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        {getCasinoPlExposureByRunner(casinoPlData, item)}
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className={`ng-tns-c1798067373-0 casino_odds_row ${
                        casinoData?.data?.response?.autoTime < 5
                          ? "suspended-box"
                          : ""
                      }`}
                      data-v-53a3d43f=""
                    >
                      <div
                        onClick={() => {
                          setBet(item, casinoData, dispatch, "back");
                        }}
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          {getOddForEntity(item, casinoData, true)}
                        </span>
                      </div>
                      <div
                        onClick={() => {
                          setBet(item, casinoData, dispatch, "lay");
                        }}
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box lay ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          {getOddForEntity(item, casinoData, false)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-box mobile-akbar ng-tns-c1798067373-0"
            data-v-53a3d43f=""
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="w-100 ng-tns-c1798067373-0"
              data-v-53a3d43f=""
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-header casino-table-header_border mt-3 ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="min_max_span ms-0 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        Min:
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        {" "}
                        100{" "}
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="min_max_span ms-1 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        Max:
                      </b>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0 ng-star-inserted"
                          data-v-53a3d43f=""
                        >
                          {" "}
                          50000{" "}
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-body ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted"
                  data-v-53a3d43f=""
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-detail ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-name d-flex w-100 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="text-danger ng-tns-c1798067373-0 ng-star-inserted"
                        data-v-53a3d43f=""
                      />{" "}
                      {"Odd"}{" "}
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-book d-md-none ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      {getCasinoPlExposureByRunner(casinoPlData, "Odd")}
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className={`ng-tns-c1798067373-0 casino_odds_row ${
                      casinoData?.data?.response?.autoTime < 5
                        ? "suspended-box"
                        : ""
                    }`}
                    data-v-53a3d43f=""
                  >
                    <div
                      onClick={() => {
                        setBet("Odd", casinoData, dispatch, "back");
                      }}
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds-box back ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        {getOddForEntity("Odd", casinoData, true)}
                      </span>
                    </div>
                    <div
                      onClick={() => {
                        setBet("Odd", casinoData, dispatch, "lay");
                      }}
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds-box lay ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        {getOddForEntity("Odd", casinoData, false)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-box mt-3 ng-tns-c1798067373-0"
            data-v-53a3d43f=""
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-right-box right-odd-box ng-tns-c1798067373-0"
              data-v-53a3d43f=""
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                data-v-53a3d43f=""
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-end ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      className="minmax-btn collapsed ng-tns-c1798067373-0"
                      href="#collapserange2bt14"
                      aria-controls="collapserange2bt14"
                      data-v-53a3d43f=""
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="range-collpase collapse ng-tns-c1798067373-0"
                      id="collapserange2bt14"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          {" "}
                          100{" "}
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            data-v-53a3d43f=""
                          >
                            {" "}
                            50000{" "}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getOddForEntity("Dulha Dulhan K-Q", casinoData, true)}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() =>
                    setBet("Dulha Dulhan K-Q", casinoData, dispatch, "back")
                  }
                  data-v-53a3d43f=""
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    Dulha Dulhan K-Q
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "Dulha Dulhan K-Q"
                  )}
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                data-v-53a3d43f=""
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-end ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      className="minmax-btn collapsed ng-tns-c1798067373-0"
                      href="#collapserange2bt15"
                      aria-controls="collapserange2bt15"
                      data-v-53a3d43f=""
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="range-collpase collapse ng-tns-c1798067373-0"
                      id="collapserange2bt15"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          {" "}
                          100{" "}
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            data-v-53a3d43f=""
                          >
                            {" "}
                            50000{" "}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getOddForEntity("Barati J-A", casinoData, true)}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  data-v-53a3d43f=""
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    Barati J-A
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getCasinoPlExposureByRunner(casinoPlData, "Barati J-A")}
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-box ng-tns-c1798067373-0"
            data-v-53a3d43f=""
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-left-box ng-tns-c1798067373-0"
              data-v-53a3d43f=""
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                data-v-53a3d43f=""
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-end ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      className="minmax-btn collapsed ng-tns-c1798067373-0"
                      href="#collapserangerb8"
                      aria-controls="collapserangerb8"
                      data-v-53a3d43f=""
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="range-collpase collapse ng-tns-c1798067373-0"
                      id="collapserangerb8"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          {" "}
                          100{" "}
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            data-v-53a3d43f=""
                          >
                            {" "}
                            12500{" "}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getOddForEntity("Red", casinoData, true)}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("Red", casinoData, dispatch, "back")}
                  data-v-53a3d43f=""
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0 ng-star-inserted"
                    data-v-53a3d43f=""
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/heart.png"
                          data-v-53a3d43f=""
                        />
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/diamond.png"
                          data-v-53a3d43f=""
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getCasinoPlExposureByRunner(casinoPlData, "Red")}
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                data-v-53a3d43f=""
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-end ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      className="minmax-btn collapsed ng-tns-c1798067373-0"
                      href="#collapserangerb9"
                      aria-controls="collapserangerb9"
                      data-v-53a3d43f=""
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="range-collpase collapse ng-tns-c1798067373-0"
                      id="collapserangerb9"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          {" "}
                          100{" "}
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            data-v-53a3d43f=""
                          >
                            {" "}
                            12500{" "}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getOddForEntity("Black", casinoData, true)}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() =>
                    setBet("Black", casinoData, dispatch, "back")
                  }
                  data-v-53a3d43f=""
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-0 ng-star-inserted"
                    data-v-53a3d43f=""
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/spade.png"
                          data-v-53a3d43f=""
                        />
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/club.png"
                          data-v-53a3d43f=""
                        />
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  {getCasinoPlExposureByRunner(casinoPlData, "Black")}
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-right-box right-cards ng-tns-c1798067373-0"
              data-v-53a3d43f=""
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="d-flex w-100 ng-tns-c1798067373-0"
                data-v-53a3d43f=""
              >
                <h4
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-end ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                  style={{ fontSize: 13 }}
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    Cards 3.75
                  </b>
                </h4>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="w-100 text-end bollywood_minmaxcards ng-tns-c1798067373-0"
                  data-v-53a3d43f=""
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserange2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserange2"
                      className="minmax-btn collapsed ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserange2"
                      className="range-collpase collapse ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          {" "}
                          100{" "}
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-0"
                        data-v-53a3d43f=""
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                          data-v-53a3d43f=""
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-0 ng-star-inserted"
                            data-v-53a3d43f=""
                          >
                            {" "}
                            12500{" "}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {["J", "Q", "K", "A"].map((card, index) => (
                <div
                  _ngcontent-ng-c1798067373=""
                  onClick={() =>
                    setBet("Card " + card, casinoData, dispatch, "back")
                  }
                  className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                  data-v-53a3d43f=""
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0 ng-star-inserted"
                      src={`https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/${card}.png`}
                      data-v-53a3d43f=""
                    />
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-book ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    {getCasinoPlExposureByRunner(
                      casinoPlData,
                      "Card " + card
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
