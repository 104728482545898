import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import CasinoVideo from "./components/CasinoVideo";
import Timer from "./components/Timer";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import {
  getCasinoBetsAPI,
  getCasinoExposureAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import {
  CARD_ARRAY,
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import PlaceBet from "./components/PlaceBet";
import PlacedBet from "./components/PlacedBet";
import CasinoPage from "./components/CasinoPage";
export default function Lucky7B() {
  const GAME_ID = "LUCKY7EU";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  const [showRules, setShowRules] = React.useState(false);
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const lastTenResultData = useQuery(
    "getResult",
    () => getCasinoLastTenResultAPI(GAME_ID),
    {
      refetchInterval: 1500,
    }
  );
  const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
    refetchInterval: 2500,
  });
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  const [selectedTab, setSelectedTab] = React.useState("game");
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.lucky7B}
      GAME_ID={GAME_ID}
      gameName={"Lucky 7 - B"}
      shortName="lucky7acards"
    >
      {/* <div className="casino-detail">
        <div className="casino-table">
          <div
            className="casino-table-full-box"
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div className="lucky7low">
              <div className="casino-odds text-center">2</div>
              <div className="casino-odds-box back casino-odds-box-theme">
                <span className="casino-odds">Low Card</span>
              </div>
              <div className="casino-nation-book text-center" />
            </div>
            <div className="lucky7">
              <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/7.png" />
            </div>
            <div className=" lucky7high">
              <div className="casino-odds text-center">2</div>
              <div className="casino-odds-box back casino-odds-box-theme">
                <span className="casino-odds">High Card</span>
              </div>
              <div className="casino-nation-book text-center" />
            </div>
          </div>
          <div className="casino-table-box mt-3">
            <div className="casino-table-left-box">
              <div className=" lucky7odds">
                <div className="casino-odds text-center">2.1</div>
                <div className="casino-odds-box back casino-odds-box-theme">
                  <span className="casino-odds">Even</span>
                </div>
                <div className="casino-nation-book text-center" />
              </div>
              <div className=" lucky7odds">
                <div className="casino-odds text-center">1.79</div>
                <div className="casino-odds-box back casino-odds-box-theme">
                  <span className="casino-odds">Odd</span>
                </div>
                <div className="casino-nation-book text-center" />
              </div>
            </div>
            <div className="casino-table-right-box">
              <div className=" lucky7odds">
                <div className="casino-odds text-center">1.95</div>
                <div className="casino-odds-box back casino-odds-box-theme">
                  <span className="casino-odds" style={{ padding: "15px" }}>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-red ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <img
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/heart.png"
                        data-v-53a3d43f=""
                        style={{ width: "18px", height: "18px" }}
                      />
                    </span>{" "}
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-red ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <img
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/diamond.png"
                        data-v-53a3d43f=""
                        style={{ width: "18px", height: "18px" }}
                      />
                    </span>
                  </span>
                </div>
                <div className="casino-nation-book text-center" />
              </div>
              <div className=" lucky7odds">
                <div className="casino-odds text-center">1.95</div>
                <div className="casino-odds-box back casino-odds-box-theme">
                  <span className="casino-odds" style={{ padding: "15px" }}>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-red ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <img
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/spade.png"
                        data-v-53a3d43f=""
                        style={{ width: "18px", height: "18px" }}
                      />
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-red ng-tns-c1798067373-0"
                      data-v-53a3d43f=""
                    >
                      <img
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/club.png"
                        data-v-53a3d43f=""
                        style={{ width: "18px", height: "18px" }}
                      />
                    </span>
                  </span>
                </div>
                <div className="casino-nation-book text-center" />
              </div>
            </div>
          </div>
          <div className="casino-table-box mt-3">
            <div className="lucky7cards">
              <div className="casino-odds w-100 text-center">4</div>
              <div className="card-odd-box-container">
                <div className="card-odd-box">
                  <div className="">
                    <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/A.png" />
                  </div>
                </div>
                <div className="card-odd-box">
                  <div className="">
                    <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/2.png" />
                  </div>
                </div>
                <div className="card-odd-box">
                  <div className="">
                    <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/3.png" />
                  </div>
                </div>
              </div>
              <div className="casino-nation-book text-center w-100" />
            </div>
            <div className="lucky7cards">
              <div className="casino-odds w-100 text-center">4</div>
              <div className="card-odd-box-container">
                <div className="card-odd-box">
                  <div className="">
                    <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/4.png" />
                  </div>
                </div>
                <div className="card-odd-box">
                  <div className="">
                    <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/5.png" />
                  </div>
                </div>
                <div className="card-odd-box">
                  <div className="">
                    <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/6.png" />
                  </div>
                </div>
              </div>
              <div className="casino-nation-book text-center w-100" />
            </div>
            <div className="lucky7cards">
              <div className="casino-odds w-100 text-center">4</div>
              <div className="card-odd-box-container">
                <div className="card-odd-box">
                  <div className="">
                    <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/8.png" />
                  </div>
                </div>
                <div className="card-odd-box">
                  <div className="">
                    <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/9.png" />
                  </div>
                </div>
                <div className="card-odd-box">
                  <div className="">
                    <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/10.png" />
                  </div>
                </div>
              </div>
              <div className="casino-nation-book text-center w-100" />
            </div>
            <div className="lucky7cards">
              <div className="casino-odds w-100 text-center">4</div>
              <div className="card-odd-box-container">
                <div className="card-odd-box">
                  <div className="">
                    <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/J.png" />
                  </div>
                </div>
                <div className="card-odd-box">
                  <div className="">
                    <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/Q.png" />
                  </div>
                </div>
                <div className="card-odd-box">
                  <div className="">
                    <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/K.png" />
                  </div>
                </div>
              </div>
              <div className="casino-nation-book text-center w-100" />
            </div>
          </div>
          <div className="casino-table-full-box lucky7acards mt-3">
            <div className="casino-odds w-100 text-center">12</div>
            <div className="card-odd-box">
              <div className="">
                <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/A.png" />
              </div>
              <div className="casino-nation-book" />
            </div>
            <div className="card-odd-box">
              <div className="">
                <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/2.png" />
              </div>
              <div className="casino-nation-book" />
            </div>
            <div className="card-odd-box">
              <div className="">
                <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/3.png" />
              </div>
              <div className="casino-nation-book" />
            </div>
            <div className="card-odd-box">
              <div className="">
                <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/4.png" />
              </div>
              <div className="casino-nation-book" />
            </div>
            <div className="card-odd-box">
              <div className="">
                <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/5.png" />
              </div>
              <div className="casino-nation-book" />
            </div>
            <div className="card-odd-box">
              <div className="">
                <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/6.png" />
              </div>
              <div className="casino-nation-book" />
            </div>
            <div className="card-odd-box">
              <div className="">
                <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/7.png" />
              </div>
              <div className="casino-nation-book" />
            </div>
            <div className="card-odd-box">
              <div className="">
                <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/8.png" />
              </div>
              <div className="casino-nation-book" />
            </div>
            <div className="card-odd-box">
              <div className="">
                <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/9.png" />
              </div>
              <div className="casino-nation-book" />
            </div>
            <div className="card-odd-box">
              <div className="">
                <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/10.png" />
              </div>
              <div className="casino-nation-book" />
            </div>
            <div className="card-odd-box">
              <div className="">
                <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/J.png" />
              </div>
              <div className="casino-nation-book" />
            </div>
            <div className="card-odd-box">
              <div className="">
                <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/Q.png" />
              </div>
              <div className="casino-nation-book" />
            </div>
            <div className="card-odd-box">
              <div className="">
                <img src="https://versionobj.ecoassetsservice.com/v23/static/front/img/cards/K.png" />
              </div>
              <div className="casino-nation-book" />
            </div>
          </div>
        </div>
      </div> */}
      <div className="casino-table">
        <div
          className="casino-table-full-box"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div className="lucky7low" style={{ width: "45%", height: "100%" }}>
            <div className="casino-odds text-center">
              {getOddForEntity("LOW Card", casinoData)}
            </div>
            <div
              className={`casino-odds-box back casino-odds-box-theme ${
                casinoData?.data?.response?.autoTime < 5
                  ? "suspended-box"
                  : ""
              }`}
              onClick={() => setBet("LOW Card", casinoData, dispatch, "back")}
            >
              <span className="casino-odds" style={{ padding: "20px" }}>
                Low Card
              </span>
            </div>
            <div className="casino-nation-book text-center">
              {getCasinoPlExposureByRunner(casinoPlData, "LOW Card")}
            </div>
          </div>
          <div className="lucky7">
            <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/7.png" />
          </div>
          <div
            className=" lucky7high"
            style={{ width: "45%", height: "100%" }}
          >
            <div className="casino-odds text-center">
              {" "}
              {getOddForEntity("HIGH Card", casinoData)}
            </div>
            <div
              className={`casino-odds-box back casino-odds-box-theme ${
                casinoData?.data?.response?.autoTime < 5
                  ? "suspended-box"
                  : ""
              }`}
              onClick={() =>
                setBet("HIGH Card", casinoData, dispatch, "back")
              }
            >
              <span className="casino-odds" style={{ padding: "20px" }}>
                High Card
              </span>
            </div>
            <div className="casino-nation-book text-center">
              {getCasinoPlExposureByRunner(casinoPlData, "HIGH Card")}
            </div>
          </div>
        </div>
        <div className="casino-table-box mt-3">
          <div
            className="casino-table-left-box"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              // width: "100%",
              justifyContent: "center",
            }}
          >
            <div className="lucky7odds" style={{ width: "45%" }}>
              <div className="casino-odds text-center">
                {" "}
                {getOddForEntity("Even", casinoData)}
              </div>
              <div
                className={`casino-odds-box back casino-odds-box-theme ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Even", casinoData, dispatch, "back")}
              >
                <span className="casino-odds" style={{ padding: "15px" }}>
                  Even
                </span>
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Even")}
              </div>
            </div>
            <div className=" lucky7odds" style={{ width: "45%" }}>
              <div className="casino-odds text-center">
                {" "}
                {getOddForEntity("Odd", casinoData)}
              </div>
              <div
                className={`casino-odds-box back casino-odds-box-theme ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Odd", casinoData, dispatch, "back")}
              >
                <span className="casino-odds" style={{ padding: "15px" }}>
                  Odd
                </span>
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Odd")}
              </div>
            </div>
          </div>
          <div
            className="casino-table-right-box"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              // width: "100%",
              justifyContent: "center",
            }}
          >
            <div className=" lucky7odds" style={{ width: "45%" }}>
              <div className="casino-odds text-center">
                {" "}
                {getOddForEntity("Red", casinoData)}
              </div>
              <div
                className={`casino-odds-box back casino-odds-box-theme ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Red", casinoData, dispatch, "back")}
              >
                <span className="casino-odds" style={{ padding: "15px" }}>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="card-red ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                      src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/heart.png"
                      data-v-53a3d43f=""
                      style={{ width: "18px", height: "18px" }}
                    />
                  </span>{" "}
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="card-red ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                      src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/diamond.png"
                      data-v-53a3d43f=""
                      style={{ width: "18px", height: "18px" }}
                    />
                  </span>
                  {/* <span className="card-icon ms-1">
                    <span className="card-red ">[</span>
                  </span> */}
                </span>
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Red")}
              </div>
            </div>
            <div className=" lucky7odds" style={{ width: "45%" }}>
              <div className="casino-odds text-center">
                {" "}
                {getOddForEntity("Black", casinoData)}
              </div>
              <div
                className={`casino-odds-box back casino-odds-box-theme ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Black", casinoData, dispatch, "back")}
              >
                <span className="casino-odds" style={{ padding: "15px" }}>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="card-red ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                      src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/spade.png"
                      data-v-53a3d43f=""
                      style={{ width: "18px", height: "18px" }}
                    />
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="card-red ng-tns-c1798067373-0"
                    data-v-53a3d43f=""
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                      src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/club.png"
                      data-v-53a3d43f=""
                      style={{ width: "18px", height: "18px" }}
                    />
                  </span>
                </span>
              </div>
              <div className="casino-nation-book text-center">
                {getCasinoPlExposureByRunner(casinoPlData, "Black")}
              </div>
            </div>
          </div>
        </div>
        <div className="casino-table-box mt-3">
          <div
            className="lucky7cards"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="casino-odds w-100 text-center">
              {" "}
              {getOddForEntity("Line 1", casinoData)}
            </div>
            <div
              className={`card-odd-box-container ${
                casinoData?.data?.response?.autoTime < 5
                  ? "suspended-box"
                  : ""
              }`}
              onClick={() => setBet("Line 1", casinoData, dispatch, "back")}
            >
              <div className="card-odd-box">
                <div className="">
                  <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/A.png" />
                </div>
              </div>
              <div className="card-odd-box">
                <div className="">
                  <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/2.png" />
                </div>
              </div>
              <div className="card-odd-box">
                <div className="">
                  <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/3.png" />
                </div>
              </div>
            </div>
            <div className="casino-nation-book text-center">
              {getCasinoPlExposureByRunner(casinoPlData, "Line 1")}
            </div>
          </div>
          <div
            className="lucky7cards"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="casino-odds w-100 text-center">
              {" "}
              {getOddForEntity("Line 2", casinoData)}
            </div>
            <div
              className={`card-odd-box-container ${
                casinoData?.data?.response?.autoTime < 5
                  ? "suspended-box"
                  : ""
              }`}
              onClick={() => setBet("Line 2", casinoData, dispatch, "back")}
            >
              <div className="card-odd-box">
                <div className="">
                  <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/4.png" />
                </div>
              </div>
              <div className="card-odd-box">
                <div className="">
                  <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/5.png" />
                </div>
              </div>
              <div className="card-odd-box">
                <div className="">
                  <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/6.png" />
                </div>
              </div>
            </div>
            <div className="casino-nation-book text-center">
              {getCasinoPlExposureByRunner(casinoPlData, "Line 2")}
            </div>
          </div>
          <div
            className="lucky7cards"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="casino-odds w-100 text-center">
              {getOddForEntity("Line 3", casinoData)}
            </div>
            <div
              className={`card-odd-box-container ${
                casinoData?.data?.response?.autoTime < 5
                  ? "suspended-box"
                  : ""
              }`}
              onClick={() => setBet("Line 3", casinoData, dispatch, "back")}
            >
              <div className="card-odd-box">
                <div className="">
                  <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/8.png" />
                </div>
              </div>
              <div className="card-odd-box">
                <div className="">
                  <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/9.png" />
                </div>
              </div>
              <div className="card-odd-box">
                <div className="">
                  <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/10.png" />
                </div>
              </div>
            </div>
            <div className="casino-nation-book text-center">
              {getCasinoPlExposureByRunner(casinoPlData, "Line 3")}
            </div>
          </div>
          <div
            className="lucky7cards"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="casino-odds w-100 text-center">
              {getOddForEntity("Line 4", casinoData)}
            </div>
            <div
              className={`card-odd-box-container ${
                casinoData?.data?.response?.autoTime < 5
                  ? "suspended-box"
                  : ""
              }`}
              onClick={() => setBet("Line 4", casinoData, dispatch, "back")}
            >
              <div className="card-odd-box">
                <div className="">
                  <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/J.png" />
                </div>
              </div>
              <div className="card-odd-box">
                <div className="">
                  <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/Q.png" />
                </div>
              </div>
              <div className="card-odd-box">
                <div className="">
                  <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/K.png" />
                </div>
              </div>
            </div>
            <div className="casino-nation-book text-center">
              {getCasinoPlExposureByRunner(casinoPlData, "Line 4")}
            </div>
          </div>
        </div>

        <div className="casino-table-full-box lucky7acards mt-3">
          <div className="casino-odds w-100 text-center">
            {" "}
            {getOddForEntity("Card 1", casinoData)}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-around",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div className="card-odd-box">
              <div
                className={` ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 1", casinoData, dispatch, "back")}
              >
                <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/A.png" />
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 1")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={` ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 2", casinoData, dispatch, "back")}
              >
                <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/2.png" />
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 2")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={` ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 3", casinoData, dispatch, "back")}
              >
                <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/3.png" />
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 3")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={` ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 4", casinoData, dispatch, "back")}
              >
                <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/4.png" />
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 4")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={` ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 5", casinoData, dispatch, "back")}
              >
                <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/5.png" />
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 5")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={` ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 6", casinoData, dispatch, "back")}
              >
                <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/6.png" />
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 6")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={` ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 7", casinoData, dispatch, "back")}
              >
                <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/7.png" />
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 7")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={` ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 8", casinoData, dispatch, "back")}
              >
                <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/8.png" />
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 8")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={` ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card 9", casinoData, dispatch, "back")}
              >
                <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/9.png" />
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 9")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={` ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() =>
                  setBet("Card 10", casinoData, dispatch, "back")
                }
              >
                <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/10.png" />
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Card 10")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={` ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card J", casinoData, dispatch, "back")}
              >
                <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/J.png" />
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Card J")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={` ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card Q", casinoData, dispatch, "back")}
              >
                <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/Q.png" />
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Card Q")}
              </div>
            </div>
            <div className="card-odd-box">
              <div
                className={` ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Card K", casinoData, dispatch, "back")}
              >
                <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/K.png" />
              </div>
              <div className="casino-nation-book">
                {getCasinoPlExposureByRunner(casinoPlData, "Card K")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
