import React, { useEffect, useState } from "react";

import { CASINO_VIDEO_URL } from "../../utils/constants";

import {
  CARD_ARRAY,
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import "./index.scoped.css";
import CasinoPage from "./components/CasinoPage";

import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";

import { useQuery } from "react-query";

import { useDispatch } from "react-redux";

export default function AndarBahar() {
  const GAME_ID = "AB_20";
  const VIDEO_ID = CASINO_VIDEO.anderBahr1;
  const dispatch = useDispatch();
  const [casinoPlData, setCasinoPlData] = useState([]);
  const [casinoCards, setCasinoCards] = useState({});
  const [andarCardsList, setAndarCardsList] = useState([]);
  const [baharCardsList, setBaharCardsList] = useState([]);
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),

    {
      refetchInterval: 800,
    }
  );

  const getCards = () => {
    // Convert andarCardsList and baharCardsList to arrays
    if (
      casinoData?.data?.response?.cards &&
      casinoData?.data?.response?.cards?.length > 0
    ) {
        const andarCards = casinoData?.data?.response?.cards[1]
          .map(String)
          .filter((s) => s !== "");
        const baharCards = casinoData?.data?.response?.cards[0]
          .map(String)
          .filter((s) => s !== "");

        const casinoCards = [];
        const casinoIndividualResultMaps = [];

        casinoCards.push({
          resultFor: "Andar",
          result: andarCards.join(","),
        });
        casinoCards.push({
          resultFor: "Bahar",
          result: baharCards.join(","),
        });

        // Initialize sets for unique results
        const andarResult = new Set();
        const baharResult = new Set();

        // Loop through cards from both lists
        for (
          let i = 0, j = 0;
          i + j < andarCards.length + baharCards.length;

        ) {
          if (j < baharCards.length) {
            const baharCardValue = baharCards[j].slice(0, -2); // Remove last 2 characters
            if (!andarResult.has(baharCardValue)) {
              baharResult.add(baharCardValue);
            }
            j++;
          }

          if (i < andarCards.length) {
            const andarCardValue = andarCards[i].slice(0, -2); // Remove last 2 characters
            if (!baharResult.has(andarCardValue)) {
              andarResult.add(andarCardValue);
            }
            i++;
          }
        }

        setAndarCardsList(Array.from(andarResult));
        setBaharCardsList(Array.from(baharResult));
      }
  };
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };

  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  useEffect(() => {
    let interval = setInterval(() => {
      getCards();
    }, 800);
    return () => {
      clearInterval(interval);
    };
  }, [andarCardsList, baharCardsList]);
  return (
    <CasinoPage
      shortName={"ab ab3"}
      casino={casinoData?.data}
      VIDEO_ID={VIDEO_ID}
      GAME_ID={GAME_ID}
      gameName={"Andar Bahar"}
    >
      <div className="casino-detail">
        <div className="casino-table">
          <div className="casino-table-box">
            <div className="andar-box">
              <div className="ab-title">ANDAR</div>
              <div className="ab-cards">
                {["A", 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]?.map(
                  (item) => (
                    <div
                      onClick={() => {
                        setBet(
                          `Andar ${
                            item == 11
                              ? "J"
                              : item == 12
                              ? "Q"
                              : item == 13
                              ? "K"
                              : item
                          }`,
                          casinoData,
                          dispatch
                        );
                      }}
                      className="card-odd-box"
                    >
                      <div className="">
                        {getOddForEntity(
                          `Andar ${
                            item == 11
                              ? "J"
                              : item == 12
                              ? "Q"
                              : item == 13
                              ? "K"
                              : item
                          }`,
                          casinoData,
                          true,
                          false,
                          true
                        ) == "SUSPENDED" &&
                        !andarCardsList?.includes(
                          String(
                            item == 11
                              ? "J"
                              : item == 12
                              ? "Q"
                              : item == 13
                              ? "K"
                              : item
                          )
                        ) ? (
                          <img
                            src={`https://versionobj.ecoassetsservice.com/v26/static/front/img/andar-bahar-cards/${0}.jpg`}
                          />
                        ) : (
                          <img
                            src={`https://versionobj.ecoassetsservice.com/v26/static/front/img/andar-bahar-cards/${
                              item == "A" ? "1" : item
                            }.jpg`}
                          />
                        )}
                      </div>
                      <div className="casino-nation-book">
                        {getCasinoPlExposureByRunner(
                          casinoData,
                          `Andar ${
                            item == 11
                              ? "J"
                              : item == 12
                              ? "Q"
                              : item == 13
                              ? "K"
                              : item
                          }`
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="bahar-box">
              <div className="ab-title">BAHAR</div>
              <div className="ab-cards">
                {["A", 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]?.map(
                  (item) => (
                    <div
                      onClick={() => {
                        setBet(
                          `Bahar ${
                            item == 11
                              ? "J"
                              : item == 12
                              ? "Q"
                              : item == 13
                              ? "K"
                              : item
                          }`,
                          casinoData,
                          dispatch
                        );
                      }}
                      className="card-odd-box"
                    >
                      <div className="">
                        {getOddForEntity(
                          `Bahar ${
                            item == 11
                              ? "J"
                              : item == 12
                              ? "Q"
                              : item == 13
                              ? "K"
                              : item
                          }`,
                          casinoData,
                          true,
                          false,
                          true
                        ) == "SUSPENDED" &&
                        !baharCardsList?.includes(
                          String(
                            item == 11
                              ? "J"
                              : item == 12
                              ? "Q"
                              : item == 13
                              ? "K"
                              : item
                          )
                        ) ? (
                          <img
                            src={`https://versionobj.ecoassetsservice.com/v26/static/front/img/andar-bahar-cards/${0}.jpg`}
                          />
                        ) : (
                          <img
                            src={`https://versionobj.ecoassetsservice.com/v26/static/front/img/andar-bahar-cards/${
                              item == "A" ? "1" : item
                            }.jpg`}
                          />
                        )}
                      </div>
                      <div className="casino-nation-book">
                        {getCasinoPlExposureByRunner(
                          casinoData,
                          `Bahar ${
                            item == 11
                              ? "J"
                              : item == 12
                              ? "Q"
                              : item == 13
                              ? "K"
                              : item
                          }`
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="casino-remark mt-1">
            <marquee scrollamount={3}>
              Payout : Bahar 1st Card 25% and All Other Andar-Bahar Cards
              100%.
            </marquee>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
