import React from "react";

export default function AB2({ data }) {
  const { casinoIndividualResultDTO } = data || {};
  const { casinoIndividualResultMaps, casinoCardResultMaps } =
    casinoIndividualResultDTO || {};

  // Extract match details
  const matchTime = new Date(
    casinoIndividualResultDTO?.createdAt + "Z"
  ).toLocaleString();
  const roundId = casinoIndividualResultDTO?.marketId;

  // Extract Winner, Suit, Odd/Even, and Joker
  const extractResult = (key) =>
    casinoIndividualResultMaps?.find((item) => item.resultFor === key)
      ?.result[0];

  const winner = extractResult("Winner");
  const suit = extractResult("Suit");
  const oddEven = extractResult("Odd/Even");
  const joker = extractResult("Joker");

  // Filter and format rows for slick sliders
  const firstRowCards = casinoCardResultMaps
    ?.find((item) => item.resultFor === "Andar 2")
    ?.result.filter((card) => card !== "1");

  const secondRowCards = casinoCardResultMaps
    ?.find((item) => item.resultFor === "Bahar 2")
    ?.result.filter((card) => card !== "1");

  // Function to render card images
  const renderCards = (cards) =>
    cards?.map((card, index) => (
      <div
        key={index}
        data-index={index}
        className="slick-slide slick-active"
        tabIndex={-1}
        aria-hidden="false"
        style={{ outline: "none", width: 47 }}
      >
        <div>
          <img
            src={`https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/${card}.png`}
            alt={`Card ${card}`}
            style={{ width: "100%", display: "inline-block" }}
          />
        </div>
      </div>
    ));

  return (
    <div className="modal-content">
      <div className="modal-body">
        <div className="casino-result-modal">
          <div className="casino-result-round-id">
            <span>
              <b>Round Id: </b> {roundId}
            </span>
            <span>
              <b>Match Time: </b>
              {matchTime}
            </span>
          </div>
          <div className="row row5 ab2-result-container">
            <div className="col-1">
              <div className="row row5">
                <div className="col-12">
                  <b>A</b>
                </div>
              </div>
              <div className="row row5">
                <div className="col-12">
                  <b>B</b>
                </div>
              </div>
            </div>
            <div className="col-2">
              <img
                src={`https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/${
                  casinoCardResultMaps?.[0]?.result[0]
                }.png`}
              />
            </div>
            {/* <div className="col-9">
              <div className="row row5">
                <div className="col-3">
                  <img
                    src={`https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/${
                      casinoCardResultMaps?.[1]?.result[0]
                    }.png`}
                  />
                </div>
                <div className="col-9 ab-result-slider">
                  <div className="slick-slider slick-initialized">
                    <div className="slick-list">
                      <div
                        className="slick-track"
                        style={{
                          width: 47,
                          opacity: 1,
                          transform: "translate3d(0px, 0px, 0px)",
                        }}
                      >
                        <div
                          data-index={0}
                          className="slick-slide slick-active slick-current"
                          tabIndex={-1}
                          aria-hidden="false"
                          style={{ outline: "none", width: 47 }}
                        >
                          <div>
                            <img
                              src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/ADD.png"
                              tabIndex={-1}
                              style={{ width: "100%", display: "inline-block" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row5">
                <div className="col-3">
                  <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/5HH.png" />
                </div>
                <div className="col-9 ab-result-slider">
                  <div className="slick-slider slick-initialized">
                    <div className="slick-list">
                      <div
                        className="slick-track"
                        style={{
                          width: 94,
                          opacity: 1,
                          transform: "translate3d(0px, 0px, 0px)",
                        }}
                      >
                        <div
                          data-index={0}
                          className="slick-slide slick-active slick-current"
                          tabIndex={-1}
                          aria-hidden="false"
                          style={{ outline: "none", width: 47 }}
                        >
                          <div>
                            <img
                              src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/10DD.png"
                              tabIndex={-1}
                              style={{ width: "100%", display: "inline-block" }}
                            />
                          </div>
                        </div>
                        <div
                          data-index={1}
                          className="slick-slide slick-active"
                          tabIndex={-1}
                          aria-hidden="false"
                          style={{ outline: "none", width: 47 }}
                        >
                          <div>
                            <img
                              src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/2SS.png"
                              tabIndex={-1}
                              style={{ width: "100%", display: "inline-block" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-9">
              <div
                className="row row5"
                style={{
                  // border: "1px solid red",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className="col-3">
                  <img
                    src={`https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/${
                      casinoCardResultMaps?.[1]?.result[0]
                    }.png`}
                  />
                </div>
                <div className="col-9 ab-result-slider">
                  <div className="slick-slider slick-initialized">
                    <div className="slick-list">
                      <div
                        className="slick-track"
                        style={{
                          width: firstRowCards?.length * 47,
                          opacity: 1,
                          transform: "translate3d(0px, 0px, 0px)",
                          display: "flex",
                          flexDirection: "row",
                          overflow: "hidden",
                          overflowX: "auto",
                          scrollBehavior: "smooth",
                          WebkitOverflowScrolling: "touch",
                          msOverflowStyle: "-ms-autohiding-scrollbar",
                        }}
                      >
                        {renderCards(firstRowCards)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row5">
                <div className="col-3">
                  <img src="https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/5HH.png" />
                </div>
                <div className="col-9 ab-result-slider">
                  <div className="slick-slider slick-initialized">
                    <div className="slick-list">
                      <div
                        className="slick-track"
                        style={{
                          width: secondRowCards?.length * 47,
                          opacity: 1,
                          transform: "translate3d(0px, 0px, 0px)",
                          display: "flex",
                          flexDirection: "row",
                          overflow: "hidden",
                          overflowX: "auto",
                          scrollBehavior: "smooth",
                          WebkitOverflowScrolling: "touch",
                          msOverflowStyle: "-ms-autohiding-scrollbar",
                        }}
                      >
                        {renderCards(secondRowCards)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2 justify-content-center">
            <div className="col-md-6">
              <div className="casino-result-desc">
                <div className="casino-result-desc-item">
                  <div>Winner</div>
                  <div>{extractResult("Winner") === "Andar" ? "A" : "B"}</div>
                </div>
                <div className="casino-result-desc-item">
                  <div>Suit</div>
                  <div>
                    {
                      {
                        DD: "Diamond",
                        HH: "Heart",
                        SS: "Spade",
                        CC: "Club",
                      }[extractResult("Suit")]
                    }
                  </div>
                </div>
                <div className="casino-result-desc-item">
                  <div>Odd/Even</div>
                  <div>{extractResult("Odd/Even")}</div>
                </div>
                <div className="casino-result-desc-item">
                  <div>Joker</div>
                  <div>{extractResult("Joker")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
