import React from "react";

export default function Card32B({ data }) {
  const { casinoIndividualResultDTO } = data || {};
  const {
    casinoCardResultMaps = [],
    casinoIndividualResultMaps = [],
    marketId,
    createdAt,
  } = casinoIndividualResultDTO || {};

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime + "Z");
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  // Extract the results like Winner, Odd/Even, etc.
  const winner = casinoIndividualResultMaps.find(
    (map) => map.resultFor === "Winner"
  );
  const winnerName = winner?.result[0] || "N/A";

  const oddEven = casinoIndividualResultMaps.find(
    (map) => map.resultFor === "Odd/Even"
  );
  const oddEvenResult = oddEven?.result[0] || "N/A";

  const blackRed = casinoIndividualResultMaps.find(
    (map) => map.resultFor === "Black/Red"
  );
  const blackRedResult = blackRed?.result[0] || "N/A";

  const total = casinoIndividualResultMaps.find(
    (map) => map.resultFor === "Total"
  );
  const totalResult = total?.result[0] || "N/A";

  const single = casinoIndividualResultMaps.find(
    (map) => map.resultFor === "Single"
  );
  const singleResult = single?.result[0] || "N/A";

  return (
    <div className="modal-body">
      <div className="casino-result-modal">
        <div className="casino-result-round-id">
          <span>
            <b>Market Id: </b> {marketId || "N/A"}
          </span>
          <span>
            <b>Match Time: </b>{" "}
            {createdAt ? formatDateTime(createdAt) : "N/A"}
          </span>
        </div>

        <div className="row mt-2">
          {/* Map through players and their cards */}
          {casinoCardResultMaps.map((player, index) => (
            <div key={index} className="col-md-3 text-center">
              <h4 className="result-title">
                {player.resultFor} -{" "}
                <span
                  className={
                    player.isWinning ? "text-success" : "text-warning"
                  }
                >
                  {player.isWinning ? "Winner" : "Not Winner"}
                </span>
              </h4>
              <div className="casino-result-cards">
                {player.result.map((card, cardIndex) => (
                  <img
                    key={cardIndex}
                    src={`https://versionobj.ecoassetsservice.com/v24/static/front/img/cards/${card}.png`}
                    alt={`Card ${card}`}
                  />
                ))}
              </div>
              {player.isWinning && (
                <div className="casino-winner-icon">
                  <i className="fas fa-trophy" />
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="row mt-2 justify-content-center">
          <div className="col-md-6">
            <div className="casino-result-desc">
              <div className="casino-result-desc-item">
                <div>Winner</div>
                <div>{winnerName}</div>
              </div>
              <div className="casino-result-desc-item">
                <div>Odd/Even</div>
                <div>{oddEvenResult}</div>
              </div>
              <div className="casino-result-desc-item">
                <div>&nbsp;</div>
                <div>{totalResult}</div>
              </div>
              <div className="casino-result-desc-item">
                <div>Black/Red</div>
                <div>{blackRedResult}</div>
              </div>
              <div className="casino-result-desc-item">
                <div>Total</div>
                <div>{totalResult}</div>
              </div>
              <div className="casino-result-desc-item">
                <div>Single</div>
                <div>{singleResult}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
