import React from "react";
import "./index.scoped.css";
import { CASINO_VIDEO, getOddForEntity, setBet } from "./CasinoGeneric";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import CasinoPage from "./components/CasinoPage";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { getCasinoOddsAPI } from "../../service/casino";
export default function CasinoWar() {
  const GAME_ID = "CASINO_WAR";
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [selectedTab, setSelectedTab] = React.useState(1);
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.casinoWar}
      GAME_ID={GAME_ID}
      shortName={"casino-war"}
      gameName={"Casino War"}
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="casino-table ng-tns-c1798067373-9 ng-star-inserted"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-table-header w-100 ng-tns-c1798067373-9 ng-star-inserted"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-nation-detail ng-tns-c1798067373-9"
          />
          {casinoData?.data?.response?.cards?.slice(1)?.map((card, index) => (
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box ng-tns-c1798067373-9"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="flip-card ng-tns-c1798067373-9"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="flip-card-inner ng-tns-c1798067373-9"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="flip-card-front ng-tns-c1798067373-9"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                      src={`https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/${card}.png`}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/**/}
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-table-full-box d-none d-md-block ng-tns-c1798067373-9"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-header ng-tns-c1798067373-9"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-nation-detail ng-tns-c1798067373-9"
            />
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box ng-tns-c1798067373-9"
            >
              1
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box ng-tns-c1798067373-9"
            >
              2
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box ng-tns-c1798067373-9"
            >
              3
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box ng-tns-c1798067373-9"
            >
              4
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box ng-tns-c1798067373-9"
            >
              5
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-odds-box ng-tns-c1798067373-9"
            >
              6
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-body ng-tns-c1798067373-9"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-9"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-9"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-9"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9"
                  >
                    Winner
                  </span>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-9"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserangewar12"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserangewar12"
                      className="minmax-btn collapsed ng-tns-c1798067373-9"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-9"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserangewar12"
                      className="range-collpase collapse ng-tns-c1798067373-9"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Winner 1", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Winner 1", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Winner 1", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Winner 2", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Winner 2", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Winner 2", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Winner 3", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Winner 3", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Winner 3", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Winner 4", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Winner 4", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Winner 4", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Winner 5", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Winner 5", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Winner 5", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Winner 6", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Winner 6", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Winner 6", casinoData, true, true)}
                </p>
              </div>
              {/**/}
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-9"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-9"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-9"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      Black
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-9"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-icon ms-1 ng-tns-c1798067373-9"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-black ng-tns-c1798067373-9"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-9"
                            src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/spade.png"
                          />
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-icon ms-1 ng-tns-c1798067373-9"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-black ng-tns-c1798067373-9"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-9"
                            src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/club.png"
                          />
                        </span>
                      </span>
                    </span>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-9"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserangewar22"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserangewar22"
                      className="minmax-btn collapsed ng-tns-c1798067373-9"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-9"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserangewar22"
                      className="range-collpase collapse ng-tns-c1798067373-9"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Black 1", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Black 1", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Black 1", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Black 2", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Black 2", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Black 2", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Black 3", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Black 3", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Black 3", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Black 4", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Black 4", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Black 4", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Black 5", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Black 5", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Black 5", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Black 6", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Black 6", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Black 6", casinoData, true, true)}
                </p>
              </div>
              {/**/}
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-9"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-9"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-9"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      Red
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-9"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-9"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                          src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/heart.png"
                        />
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-9"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-9"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                          src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/diamond.png"
                        />
                      </span>
                    </span>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-9"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserangewar32"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserangewar32"
                      className="minmax-btn collapsed ng-tns-c1798067373-9"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-9"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserangewar32"
                      className="range-collpase collapse ng-tns-c1798067373-9"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Red 1", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Red 1", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Red 1", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Red 2", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Red 2", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Red 2", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Red 3", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Red 3", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Red 3", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Red 4", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Red 4", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Red 4", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Red 5", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Red 5", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Red 5", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Red 6", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Red 6", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Red 6", casinoData, true, true)}
                </p>
              </div>
              {/**/}
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-9"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-9"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-9"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9"
                  >
                    Odds
                  </span>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-9"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserangewar42"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserangewar42"
                      className="minmax-btn collapsed ng-tns-c1798067373-9"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-9"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserangewar42"
                      className="range-collpase collapse ng-tns-c1798067373-9"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Odd 1", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Odd 1", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Odd 1", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Odd 2", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Odd 2", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Odd 2", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Odd 3", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Odd 3", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Odd 3", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Odd 4", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Odd 4", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Odd 4", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Odd 5", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Odd 5", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Odd 5", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Odd 6", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Odd 6", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Odd 6", casinoData, true, true)}
                </p>
              </div>
              {/**/}
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-9"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-9"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-9"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9"
                  >
                    Even
                  </span>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-9"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserangewar52"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserangewar52"
                      className="minmax-btn collapsed ng-tns-c1798067373-9"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-9"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserangewar52"
                      className="range-collpase collapse ng-tns-c1798067373-9"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Even 1", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Even 1", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Even 1", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Even 2", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Even 2", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Even 2", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Even 3", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Even 3", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Even 3", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Even 4", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Even 4", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Even 4", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Even 5", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Even 5", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Even 5", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Even 6", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Even 6", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Even 6", casinoData, true, true)}
                </p>
              </div>
              {/**/}
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-9"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-9"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-9"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9"
                    src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/spade.png"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-9"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserangewar62"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserangewar62"
                      className="minmax-btn collapsed ng-tns-c1798067373-9"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-9"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserangewar62"
                      className="range-collpase collapse ng-tns-c1798067373-9"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Spade 1", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Spade 1", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Spade 1", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Spade 2", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Spade 2", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Spade 2", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Spade 3", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Spade 3", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Spade 3", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Spade 4", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Spade 4", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Spade 4", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Spade 5", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Spade 5", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Spade 5", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Spade 6", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Spade 6", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Spade 6", casinoData, true, true)}
                </p>
              </div>
              {/**/}
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-9"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-9"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-9"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9"
                    src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/club.png"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-9"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserangewar72"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserangewar72"
                      className="minmax-btn collapsed ng-tns-c1798067373-9"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-9"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserangewar72"
                      className="range-collpase collapse ng-tns-c1798067373-9"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Club 1", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Club 1", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Club 1", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Club 2", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Club 2", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Club 2", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Club 3", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Club 3", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Club 3", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Club 4", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Club 4", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Club 4", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Club 5", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Club 5", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Club 5", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() => setBet("Club 6", casinoData, dispatch, "back")}
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Club 6", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Club 6", casinoData, true, true)}
                </p>
              </div>
              {/**/}
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-9"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-9"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-9"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9"
                    src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/heart.png"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-9"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserangewar82"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserangewar82"
                      className="minmax-btn collapsed ng-tns-c1798067373-9"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-9"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserangewar82"
                      className="range-collpase collapse ng-tns-c1798067373-9"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Heart 1", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Heart 1", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Heart 1", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Heart 2", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Heart 2", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Heart 2", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Heart 3", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Heart 3", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Heart 3", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Heart 4", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Heart 4", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Heart 4", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Heart 5", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Heart 5", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Heart 5", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Heart 6", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Heart 6", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Heart 6", casinoData, true, true)}
                </p>
              </div>
              {/**/}
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-row ng-tns-c1798067373-9"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-9"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-name ng-tns-c1798067373-9"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-9"
                    src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/diamond.png"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-9"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserangewar92"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserangewar92"
                      className="minmax-btn collapsed ng-tns-c1798067373-9"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-9"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserangewar92"
                      className="range-collpase collapse ng-tns-c1798067373-9"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-9"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-9"
                        >
                          100000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Diamond 1", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Diamond 1", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Diamond 1", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Diamond 2", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Diamond 2", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Diamond 2", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Diamond 3", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Diamond 3", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Diamond 3", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Diamond 4", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Diamond 4", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Diamond 4", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Diamond 5", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Diamond 5", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Diamond 5", casinoData, true, true)}
                </p>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-9 back casino-odds-box ng-star-inserted ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                } `}
                onClick={() =>
                  setBet("Diamond 6", casinoData, dispatch, "back")
                }
              >
                <span
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds ng-tns-c1798067373-9"
                >
                  {" "}
                  {getOddForEntity("Diamond 6", casinoData, true)}
                </span>
                <p
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-9"
                >
                  {getOddForEntity("Diamond 6", casinoData, true, true)}
                </p>
              </div>
              {/**/}
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-table-full-box d-md-none ng-tns-c1798067373-9"
        >
          <ul
            _ngcontent-ng-c1798067373=""
            className="menu-tabs d-xl-none nav nav-tabs ng-tns-c1798067373-9"
          >
            {[1, 2, 3, 4, 5, 6]?.map((item) => (
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-9"
              >
                <button
                  _ngcontent-ng-c1798067373=""
                  type="button"
                  onClick={() => setSelectedTab(item)}
                  className={`ng-tns-c1798067373-9 ${
                    selectedTab === item ? "active" : ""
                  } nav-link`}
                >
                  {" "}
                  {item}{" "}
                </button>
              </li>
            ))}
          </ul>
          <div
            _ngcontent-ng-c1798067373=""
            className="tab-content ng-tns-c1798067373-9"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="ng-tns-c1798067373-9 active fade show tab-pane"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-body ng-tns-c1798067373-9"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="row row5 ng-tns-c1798067373-9"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="col-6 ng-tns-c1798067373-9"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-row ng-tns-c1798067373-9 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-9"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-name ng-tns-c1798067373-9"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-9 ng-star-inserted"
                          >
                            Winner
                          </span>
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-9"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c1798067373-9"
                              href="#collapserange2m10291"
                              aria-controls="collapserange2m10291"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-9"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="range-collpase collapse ng-tns-c1798067373-9"
                              id="collapserange2m10291"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-9 ng-star-inserted"
                                  >
                                    {" "}
                                    12500{" "}
                                  </span>
                                  {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className={`ng-tns-c1798067373-9 back ${
                          casinoData?.data?.response?.autoTime < 5
                            ? "suspended-box"
                            : ""
                        } casino-odds-box`}
                        onClick={() =>
                          setBet(
                            "Winner " + selectedTab,
                            casinoData,
                            dispatch,
                            "back"
                          )
                        }
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-9"
                        >
                          {getOddForEntity(
                            "Winner " + selectedTab,
                            casinoData,
                            true
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-row ng-tns-c1798067373-9 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-9"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-name ng-tns-c1798067373-9"
                        >
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-9 ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-9"
                            >
                              Black
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="card-icon ms-1 ng-tns-c1798067373-9"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-black ng-tns-c1798067373-9"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                  src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/spade.png"
                                />
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="card-icon ms-1 ng-tns-c1798067373-9"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-black ng-tns-c1798067373-9"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                  src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/club.png"
                                />
                              </span>
                            </span>
                          </div>
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-9"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c1798067373-9"
                              href="#collapserange2m10292"
                              aria-controls="collapserange2m10292"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-9"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="range-collpase collapse ng-tns-c1798067373-9"
                              id="collapserange2m10292"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-9 ng-star-inserted"
                                  >
                                    {" "}
                                    12500{" "}
                                  </span>
                                  {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className={`ng-tns-c1798067373-9 back ${
                          casinoData?.data?.response?.autoTime < 5
                            ? "suspended-box"
                            : ""
                        } casino-odds-box`}
                        onClick={() =>
                          setBet(
                            "Black " + selectedTab,
                            casinoData,
                            dispatch,
                            "back"
                          )
                        }
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-9"
                        >
                          {getOddForEntity(
                            "Black " + selectedTab,
                            casinoData,
                            true
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-row ng-tns-c1798067373-9 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-9"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-name ng-tns-c1798067373-9"
                        >
                          {/**/}
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-9 ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-9"
                            >
                              Red
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="card-icon ms-1 ng-tns-c1798067373-9"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-red ng-tns-c1798067373-9"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                  src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/heart.png"
                                />
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="card-icon ms-1 ng-tns-c1798067373-9"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="card-red ng-tns-c1798067373-9"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                  src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/diamond.png"
                                />
                              </span>
                            </span>
                          </div>
                          {/**/}
                          {/**/}
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-9"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c1798067373-9"
                              href="#collapserange2m10293"
                              aria-controls="collapserange2m10293"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-9"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="range-collpase collapse ng-tns-c1798067373-9"
                              id="collapserange2m10293"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-9 ng-star-inserted"
                                  >
                                    {" "}
                                    12500{" "}
                                  </span>
                                  {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className={`ng-tns-c1798067373-9 back ${
                          casinoData?.data?.response?.autoTime < 5
                            ? "suspended-box"
                            : ""
                        } casino-odds-box`}
                        onClick={() =>
                          setBet(
                            "Red " + selectedTab,
                            casinoData,
                            dispatch,
                            "back"
                          )
                        }
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-9"
                        >
                          {getOddForEntity(
                            "Red " + selectedTab,
                            casinoData,
                            true
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-row ng-tns-c1798067373-9 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-9"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-name ng-tns-c1798067373-9"
                        >
                          {/**/}
                          {/**/}
                          {/**/}
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-9 ng-star-inserted"
                          >
                            Odds
                          </span>
                          {/**/}
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-9"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c1798067373-9"
                              href="#collapserange2m10294"
                              aria-controls="collapserange2m10294"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-9"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="range-collpase collapse ng-tns-c1798067373-9"
                              id="collapserange2m10294"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-9 ng-star-inserted"
                                  >
                                    {" "}
                                    12500{" "}
                                  </span>
                                  {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className={`ng-tns-c1798067373-9 back ${
                          casinoData?.data?.response?.autoTime < 5
                            ? "suspended-box"
                            : ""
                        } casino-odds-box`}
                        onClick={() =>
                          setBet(
                            "Odd " + selectedTab,
                            casinoData,
                            dispatch,
                            "back"
                          )
                        }
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-9"
                        >
                          {getOddForEntity(
                            "Odd " + selectedTab,
                            casinoData,
                            true
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-row ng-tns-c1798067373-9 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-9"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-name ng-tns-c1798067373-9"
                        >
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-9 ng-star-inserted"
                          >
                            Even
                          </span>
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-9"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c1798067373-9"
                              href="#collapserange2m10295"
                              aria-controls="collapserange2m10295"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-9"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="range-collpase collapse ng-tns-c1798067373-9"
                              id="collapserange2m10295"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-9 ng-star-inserted"
                                  >
                                    {" "}
                                    12500{" "}
                                  </span>
                                  {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className={`ng-tns-c1798067373-9 back ${
                          casinoData?.data?.response?.autoTime < 5
                            ? "suspended-box"
                            : ""
                        } casino-odds-box`}
                        onClick={() =>
                          setBet(
                            "Even " + selectedTab,
                            casinoData,
                            dispatch,
                            "back"
                          )
                        }
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-9"
                        >
                          {getOddForEntity(
                            "Even " + selectedTab,
                            casinoData,
                            true
                          )}
                        </span>
                      </div>
                    </div>
                    {/**/}
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="col-6 ng-tns-c1798067373-9"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-row ng-tns-c1798067373-9 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-9"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-name ng-tns-c1798067373-9"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-9 ng-star-inserted"
                            src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/spade.png"
                          />
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-9"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c1798067373-9"
                              href="#collapserange2m10296"
                              aria-controls="collapserange2m10296"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-9"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="range-collpase collapse ng-tns-c1798067373-9"
                              id="collapserange2m10296"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-9 ng-star-inserted"
                                  >
                                    {" "}
                                    12500{" "}
                                  </span>
                                  {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className={`ng-tns-c1798067373-9 back ${
                          casinoData?.data?.response?.autoTime < 5
                            ? "suspended-box"
                            : ""
                        } casino-odds-box`}
                        onClick={() =>
                          setBet(
                            "Spade " + selectedTab,
                            casinoData,
                            dispatch,
                            "back"
                          )
                        }
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-9"
                        >
                          {getOddForEntity(
                            "Spade " + selectedTab,
                            casinoData,
                            true
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-row ng-tns-c1798067373-9 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-9"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-name ng-tns-c1798067373-9"
                        >
                          {/**/}
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-9 ng-star-inserted"
                            src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/club.png"
                          />
                          {/**/}
                          {/**/}
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-9"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c1798067373-9"
                              href="#collapserange2m10297"
                              aria-controls="collapserange2m10297"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-9"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="range-collpase collapse ng-tns-c1798067373-9"
                              id="collapserange2m10297"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-9 ng-star-inserted"
                                  >
                                    {" "}
                                    12500{" "}
                                  </span>
                                  {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className={`ng-tns-c1798067373-9 back ${
                          casinoData?.data?.response?.autoTime < 5
                            ? "suspended-box"
                            : ""
                        } casino-odds-box`}
                        onClick={() =>
                          setBet(
                            "Club " + selectedTab,
                            casinoData,
                            dispatch,
                            "back"
                          )
                        }
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-9"
                        >
                          {getOddForEntity(
                            "Club " + selectedTab,
                            casinoData,
                            true
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-row ng-tns-c1798067373-9 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-9"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-name ng-tns-c1798067373-9"
                        >
                          {/**/}
                          {/**/}
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-9 ng-star-inserted"
                            src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/heart.png"
                          />
                          {/**/}
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-9"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c1798067373-9"
                              href="#collapserange2m10298"
                              aria-controls="collapserange2m10298"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-9"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="range-collpase collapse ng-tns-c1798067373-9"
                              id="collapserange2m10298"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-9 ng-star-inserted"
                                  >
                                    {" "}
                                    12500{" "}
                                  </span>
                                  {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className={`ng-tns-c1798067373-9 back ${
                          casinoData?.data?.response?.autoTime < 5
                            ? "suspended-box"
                            : ""
                        } casino-odds-box`}
                        onClick={() =>
                          setBet(
                            "Heart " + selectedTab,
                            casinoData,
                            dispatch,
                            "back"
                          )
                        }
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-9"
                        >
                          {getOddForEntity(
                            "Heart " + selectedTab,
                            casinoData,
                            true
                          )}
                        </span>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-row ng-tns-c1798067373-9 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-9"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-name ng-tns-c1798067373-9"
                        >
                          {/**/}
                          {/**/}
                          {/**/}
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-9 ng-star-inserted"
                            src="https://versionobj.ecoassetsservice.com/v26/static/front/img/cards/diamond.png"
                          />
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="min_max_collapse ng-tns-c1798067373-9"
                          >
                            <a
                              _ngcontent-ng-c1798067373=""
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed ng-tns-c1798067373-9"
                              href="#collapserange2m10299"
                              aria-controls="collapserange2m10299"
                            >
                              <i
                                _ngcontent-ng-c1798067373=""
                                className="fas fa-info-circle ng-tns-c1798067373-9"
                              />
                            </a>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="range-collpase collapse ng-tns-c1798067373-9"
                              id="collapserange2m10299"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-0 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Min:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="min_max_span ms-1 ng-tns-c1798067373-9"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  Max:
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-9 ng-star-inserted"
                                  >
                                    {" "}
                                    12500{" "}
                                  </span>
                                  {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className={`ng-tns-c1798067373-9 back ${
                          casinoData?.data?.response?.autoTime < 5
                            ? "suspended-box"
                            : ""
                        } casino-odds-box`}
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds ng-tns-c1798067373-9"
                        >
                          {getOddForEntity(
                            "Diamond " + selectedTab,
                            casinoData,
                            true
                          )}
                        </span>
                      </div>
                    </div>
                    {/**/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
